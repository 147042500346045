<template>
  <DashboardTemplate :sections="sections" :grid-rows="4" :grid-area-component="gridAreaComponent" />
</template>

<script>
import DashboardTemplate from '@/components/dashboard/Template.vue';

export default {
  name: 'AnalyticsSelection',
  components: { DashboardTemplate },
  data() {
    return {
      gridAreaComponent: '"analytics-selector-instagram analytics-selector-instagram analytics-selector-vacancies analytics-selector-vacancies analytics-selector-paes analytics-selector-paes" "analytics-selector-instagram analytics-selector-instagram analytics-selector-vacancies analytics-selector-vacancies analytics-selector-paes analytics-selector-paes" ". . . . . ." ". . . . . ."',
    };
  },
  computed: {
    sections() {
      const sections = [
        {
          name: 'analytics-selector-instagram',
          title: '',
          fullSpace: true,
          permissions: ['headmaster'],
          showTitle: false,
          gridArea: 'analytics-selector-instagram',
        },
        // {
        //   name: 'analytics-selector-vacancies',
        //   title: '',
        //   fullSpace: true,
        //   showTitle: false,
        //   permissions: ['headmaster'],
        //   gridArea: 'analytics-selector-vacancies',
        // },
        // {
        //   name: 'analytics-selector-paes',
        //   title: '',
        //   fullSpace: true,
        //   showTitle: false,
        //   permissions: ['headmaster'],
        //   gridArea: 'analytics-selector-paes',
        //   background: '#E0E7FF',
        // },
      ];
      return sections;
    },
  },
};
</script>
